import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Audio over HTTPS",
  "path": "/Software/Mobile_Apps_HTTPS_and_RTSP/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "3-way to use the audio function if your camera has been integrated via HTTPS",
  "social": "/images/Search/QI_SearchThumb_SetupWizard.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-iOS-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='Audio over HTTPS' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='3-way to use the audio function if your camera has been integrated via HTTPS' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Mobile_Apps_HTTPS_and_RTSP/' locationFR='/fr/Software/Mobile_Apps_HTTPS_and_RTSP/' crumbLabel="HTTPS" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#https-and-rtsp"
        }}>{`HTTPS and RTSP`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#instarvision-handy-app"
            }}>{`InstarVision Handy App`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#integration-of-the-rtsp-stream-into-a-third-party-app"
            }}>{`Integration of the RTSP stream into a third-party app`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#mjpeg-stream-320p"
                }}>{`MJPEG Stream 320p`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#rtsp-stream-1080p"
                }}>{`RTSP Stream 1080p`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <blockquote>
      <p parentName="blockquote">{`Diskutieren Sie diesen Artikel im `}<a parentName="p" {...{
          "href": "https://forum.instar.de/t/rtsp-videostream-und-https/26169"
        }}>{`INSTAR Forum`}</a></p>
    </blockquote>
    <h1 {...{
      "id": "instar-full-hd-camera-series",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-full-hd-camera-series",
        "aria-label": "instar full hd camera series permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Full HD Camera Series`}</h1>
    <h2 {...{
      "id": "https-and-rtsp",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#https-and-rtsp",
        "aria-label": "https and rtsp permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HTTPS and RTSP`}</h2>
    <p><strong parentName="p">{`Prerequisites for FTP remote access:`}</strong>{` `}<a parentName="p" {...{
        "href": "/en/Software/Mobile_Access_Over_HTTPS/"
      }}>{`Configuration`}</a></p>
    <h3 {...{
      "id": "instarvision-handy-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#instarvision-handy-app",
        "aria-label": "instarvision handy app permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InstarVision Handy App`}</h3>
    <p>{`For `}<a parentName="p" {...{
        "href": "/en/Software/Android/InstarVision/DDNS/"
      }}>{`Android`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/iPhone/DDNS/"
      }}>{`iPhone`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
      }}>{`iPad`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Windows_Phone/DDNS/"
      }}>{`Windows Phone`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Metro/DDNS/"
      }}>{`Windows Metro`}</a></p>
    <p>{`If you have included the video stream of your INSTAR 1080p camera in the InstarVision as explained above (red button), the camera will display the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Multimedia/Video/"
      }}>{`Second Stream`}</a>{` (not Full HD). This requires much less bandwidth and is thus ideally suited for mobile use on a small display. On devices with larger displays and in their own network, where bandwidth requirements and data volumes do not matter, you might want to have the full resolution. The following pictures explain how to take the Full HD RTSP video stream from your INSTAR 1080p camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/386a0a27982e363751fb653fd8b63d2a/ac99c/InstarVision_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "130.43478260869566%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAaABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHzM6gBenIVB//EABcQAAMBAAAAAAAAAAAAAAAAAAESIBH/2gAIAQEAAQUCNlV2P//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABkQAAEFAAAAAAAAAAAAAAAAACAAAREhMf/aAAgBAQAGPwIrTRo//8QAGxAAAgIDAQAAAAAAAAAAAAAAAREAECAhQVH/2gAIAQEAAT8hAIRCjYT8RRu+42H/2gAMAwEAAgADAAAAEAAIAP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB8QAAIBAgcAAAAAAAAAAAAAAAERAEFhECAhMXGBof/aAAgBAQABPxBAXUtCAIISk0WJkOo2RhOBSEm58yf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/386a0a27982e363751fb653fd8b63d2a/e4706/InstarVision_01.avif 230w", "/en/static/386a0a27982e363751fb653fd8b63d2a/d1af7/InstarVision_01.avif 460w", "/en/static/386a0a27982e363751fb653fd8b63d2a/7f308/InstarVision_01.avif 920w", "/en/static/386a0a27982e363751fb653fd8b63d2a/e1c99/InstarVision_01.avif 1380w", "/en/static/386a0a27982e363751fb653fd8b63d2a/3ff05/InstarVision_01.avif 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/386a0a27982e363751fb653fd8b63d2a/a0b58/InstarVision_01.webp 230w", "/en/static/386a0a27982e363751fb653fd8b63d2a/bc10c/InstarVision_01.webp 460w", "/en/static/386a0a27982e363751fb653fd8b63d2a/966d8/InstarVision_01.webp 920w", "/en/static/386a0a27982e363751fb653fd8b63d2a/445df/InstarVision_01.webp 1380w", "/en/static/386a0a27982e363751fb653fd8b63d2a/0abaa/InstarVision_01.webp 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/386a0a27982e363751fb653fd8b63d2a/e83b4/InstarVision_01.jpg 230w", "/en/static/386a0a27982e363751fb653fd8b63d2a/e41a8/InstarVision_01.jpg 460w", "/en/static/386a0a27982e363751fb653fd8b63d2a/4d836/InstarVision_01.jpg 920w", "/en/static/386a0a27982e363751fb653fd8b63d2a/89b2d/InstarVision_01.jpg 1380w", "/en/static/386a0a27982e363751fb653fd8b63d2a/ac99c/InstarVision_01.jpg 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/386a0a27982e363751fb653fd8b63d2a/4d836/InstarVision_01.jpg",
              "alt": "InstarVision - add a RTSP Camera",
              "title": "InstarVision - add a RTSP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2948f169a0717d80d0e0ad87e895d707/e1596/InstarVision_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAECB6wWo3//xAAaEAEBAAIDAAAAAAAAAAAAAAACAQADERMU/9oACAEBAAEFAuo4dEsZkSWegyK8r//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAMAAwAAAAAAAAAAAAAAAAABIRExM//aAAgBAQAGPwJQ5jRuoatMn//EABkQAQEBAQEBAAAAAAAAAAAAAAERADEhcf/aAAgBAQABPyFBDdr3Tnp+uizjpILhNBPUVxsHu//aAAwDAQACAAMAAAAQFy//xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QdFD/xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QgX//xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhMVGB0f/aAAgBAQABPxBlDCN+B9nhp7vBeQQC+Q1dd9yA1eBOU3kzibBN9z//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2948f169a0717d80d0e0ad87e895d707/e4706/InstarVision_02.avif 230w", "/en/static/2948f169a0717d80d0e0ad87e895d707/d1af7/InstarVision_02.avif 460w", "/en/static/2948f169a0717d80d0e0ad87e895d707/7f308/InstarVision_02.avif 920w", "/en/static/2948f169a0717d80d0e0ad87e895d707/e1c99/InstarVision_02.avif 1380w", "/en/static/2948f169a0717d80d0e0ad87e895d707/76ea5/InstarVision_02.avif 1840w", "/en/static/2948f169a0717d80d0e0ad87e895d707/95b77/InstarVision_02.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2948f169a0717d80d0e0ad87e895d707/a0b58/InstarVision_02.webp 230w", "/en/static/2948f169a0717d80d0e0ad87e895d707/bc10c/InstarVision_02.webp 460w", "/en/static/2948f169a0717d80d0e0ad87e895d707/966d8/InstarVision_02.webp 920w", "/en/static/2948f169a0717d80d0e0ad87e895d707/445df/InstarVision_02.webp 1380w", "/en/static/2948f169a0717d80d0e0ad87e895d707/78de1/InstarVision_02.webp 1840w", "/en/static/2948f169a0717d80d0e0ad87e895d707/8df42/InstarVision_02.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2948f169a0717d80d0e0ad87e895d707/e83b4/InstarVision_02.jpg 230w", "/en/static/2948f169a0717d80d0e0ad87e895d707/e41a8/InstarVision_02.jpg 460w", "/en/static/2948f169a0717d80d0e0ad87e895d707/4d836/InstarVision_02.jpg 920w", "/en/static/2948f169a0717d80d0e0ad87e895d707/89b2d/InstarVision_02.jpg 1380w", "/en/static/2948f169a0717d80d0e0ad87e895d707/5726f/InstarVision_02.jpg 1840w", "/en/static/2948f169a0717d80d0e0ad87e895d707/e1596/InstarVision_02.jpg 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2948f169a0717d80d0e0ad87e895d707/4d836/InstarVision_02.jpg",
              "alt": "InstarVision - add a RTSP Camera",
              "title": "InstarVision - add a RTSP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2bf85eaaaec2c5f45da17c943d1fa296/1839c/InstarVision_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "122.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAYABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeXlyY0VIAf/xAAWEAEBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQEAAQUCRN//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ah//xAAZEAEAAwEBAAAAAAAAAAAAAAABABARITH/2gAIAQEAAT8hQmJiJy3y/wD/2gAMAwEAAgADAAAAEJAAAP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EABoQAQEBAAMBAAAAAAAAAAAAAAERABAhYUH/2gAIAQEAAT8QnCO8HeDh7rEkI96cBhnzn//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2bf85eaaaec2c5f45da17c943d1fa296/e4706/InstarVision_03.avif 230w", "/en/static/2bf85eaaaec2c5f45da17c943d1fa296/d1af7/InstarVision_03.avif 460w", "/en/static/2bf85eaaaec2c5f45da17c943d1fa296/7f308/InstarVision_03.avif 920w", "/en/static/2bf85eaaaec2c5f45da17c943d1fa296/456c2/InstarVision_03.avif 1196w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2bf85eaaaec2c5f45da17c943d1fa296/a0b58/InstarVision_03.webp 230w", "/en/static/2bf85eaaaec2c5f45da17c943d1fa296/bc10c/InstarVision_03.webp 460w", "/en/static/2bf85eaaaec2c5f45da17c943d1fa296/966d8/InstarVision_03.webp 920w", "/en/static/2bf85eaaaec2c5f45da17c943d1fa296/31d32/InstarVision_03.webp 1196w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2bf85eaaaec2c5f45da17c943d1fa296/e83b4/InstarVision_03.jpg 230w", "/en/static/2bf85eaaaec2c5f45da17c943d1fa296/e41a8/InstarVision_03.jpg 460w", "/en/static/2bf85eaaaec2c5f45da17c943d1fa296/4d836/InstarVision_03.jpg 920w", "/en/static/2bf85eaaaec2c5f45da17c943d1fa296/1839c/InstarVision_03.jpg 1196w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2bf85eaaaec2c5f45da17c943d1fa296/4d836/InstarVision_03.jpg",
              "alt": "InstarVision - add a RTSP Camera",
              "title": "InstarVision - add a RTSP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ef71302a3e0737c72cb32a28a4336148/e1596/InstarVision_04.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAECB7QSo1//xAAZEAEAAwEBAAAAAAAAAAAAAAACAAEREwP/2gAIAQEAAQUC5mHwrGcaU7mqV6v/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAADAQEBAAAAAAAAAAAAAAAAASEREjP/2gAIAQEABj8CUPMaM6qGrTT/xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAxIXH/2gAIAQEAAT8hSQ3a9z4lfrhlcdJF4Jpp6isxsHu//9oADAMBAAIAAwAAABDHP//EABcRAAMBAAAAAAAAAAAAAAAAAAABEUH/2gAIAQMBAT8QdwUP/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/EIF//8QAGxABAQEBAQADAAAAAAAAAAAAAREhADGBsdH/2gAIAQEAAT8QuphG7A/e9uM1r98WCCgVzjQm3jnzxavI+I3K9nEdKb3/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ef71302a3e0737c72cb32a28a4336148/e4706/InstarVision_04.avif 230w", "/en/static/ef71302a3e0737c72cb32a28a4336148/d1af7/InstarVision_04.avif 460w", "/en/static/ef71302a3e0737c72cb32a28a4336148/7f308/InstarVision_04.avif 920w", "/en/static/ef71302a3e0737c72cb32a28a4336148/e1c99/InstarVision_04.avif 1380w", "/en/static/ef71302a3e0737c72cb32a28a4336148/76ea5/InstarVision_04.avif 1840w", "/en/static/ef71302a3e0737c72cb32a28a4336148/95b77/InstarVision_04.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ef71302a3e0737c72cb32a28a4336148/a0b58/InstarVision_04.webp 230w", "/en/static/ef71302a3e0737c72cb32a28a4336148/bc10c/InstarVision_04.webp 460w", "/en/static/ef71302a3e0737c72cb32a28a4336148/966d8/InstarVision_04.webp 920w", "/en/static/ef71302a3e0737c72cb32a28a4336148/445df/InstarVision_04.webp 1380w", "/en/static/ef71302a3e0737c72cb32a28a4336148/78de1/InstarVision_04.webp 1840w", "/en/static/ef71302a3e0737c72cb32a28a4336148/8df42/InstarVision_04.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ef71302a3e0737c72cb32a28a4336148/e83b4/InstarVision_04.jpg 230w", "/en/static/ef71302a3e0737c72cb32a28a4336148/e41a8/InstarVision_04.jpg 460w", "/en/static/ef71302a3e0737c72cb32a28a4336148/4d836/InstarVision_04.jpg 920w", "/en/static/ef71302a3e0737c72cb32a28a4336148/89b2d/InstarVision_04.jpg 1380w", "/en/static/ef71302a3e0737c72cb32a28a4336148/5726f/InstarVision_04.jpg 1840w", "/en/static/ef71302a3e0737c72cb32a28a4336148/e1596/InstarVision_04.jpg 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ef71302a3e0737c72cb32a28a4336148/4d836/InstarVision_04.jpg",
              "alt": "InstarVision - add a RTSP Camera",
              "title": "InstarVision - add a RTSP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`First, the camera must have the RTSP profile (s. `}<a parentName="li" {...{
          "href": "/en/Software/Mobile_Access_Over_HTTPS/"
        }}>{`Use RTSP Videostream`}</a>{`)into the InstarVision.`}</li>
      <li parentName="ol">{`To access the 1080p video stream, you must now go to the video settings (s. Cog Symbol)`}</li>
      <li parentName="ol">{`Go to `}<strong parentName="li">{`Resolution`}</strong>{` and select `}<strong parentName="li">{`high`}</strong>{`.`}</li>
      <li parentName="ol">{`Now you have integrated the Full HD Stream of your camera.`}</li>
    </ol>
    <p><strong parentName="p">{`Attention`}</strong>{`: The bandwidth requirements of the 1080p video stream are very high. You will need an excellent network connection to play the stream without errors. With insufficient bandwidth, you will be able to see digital artifacts in the video image. You can then try to set the video settings of the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Multimedia/Video/"
      }}>{`1080P Streams`}</a>{` down a bit.`}</p>
    <h3 {...{
      "id": "integration-of-the-rtsp-stream-into-a-third-party-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#integration-of-the-rtsp-stream-into-a-third-party-app",
        "aria-label": "integration of the rtsp stream into a third party app permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Integration of the RTSP stream into a third-party app`}</h3>
    <p><strong parentName="p">{`Shown at the example of the`}</strong>{` `}<a parentName="p" {...{
        "href": "/en/Software/Android/IP_Cam_Viewer/"
      }}>{`IP Cam Viewer`}</a></p>
    <p>{`The first picture shows the integration via the `}<strong parentName="p">{`INSTAR profile`}</strong>{`. The second is the `}<strong parentName="p">{`Generic RTSP Stream over TCP`}</strong>{`. In the first case, the MJPEG stream is picked up in the second resolution, which is suitable for mobile access. In the RTSP stream, we determine the resolution by the number attached to the camera URL - `}<strong parentName="p">{`/11`}</strong>{` is the 1080p video stream (the two smaller streams are `}<strong parentName="p">{`/12`}</strong>{` and `}<strong parentName="p">{`/13`}</strong>{` available - s. `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9008_HD/Video_Streaming/"
      }}>{`RTSP Video Streaming`}</a>{`).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/43911e3d5eee235a5514a0833c8912c0/ac99c/IP_Cam_Viewer_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFAwT/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAGSnRiMXA//xAAaEAACAwEBAAAAAAAAAAAAAAAAAgEDMwQT/9oACAEBAAEFApdz0ci1jq2q1g//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAABAwUAAAAAAAAAAAAAAAAAATKBAhARIXH/2gAIAQEABj8Cco5TeVkgp7b/xAAdEAABAgcAAAAAAAAAAAAAAAAAATERUWFxobHx/9oACAEBAAE/IaqudcSI4nEaBghp/9oADAMBAAIAAwAAABBgz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAAICAgMBAAAAAAAAAAAAAAABETGx8FGRocH/2gAIAQEAAT8QjqU+g2X2CBpOsrBZtZouT44R/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/43911e3d5eee235a5514a0833c8912c0/e4706/IP_Cam_Viewer_01.avif 230w", "/en/static/43911e3d5eee235a5514a0833c8912c0/d1af7/IP_Cam_Viewer_01.avif 460w", "/en/static/43911e3d5eee235a5514a0833c8912c0/7f308/IP_Cam_Viewer_01.avif 920w", "/en/static/43911e3d5eee235a5514a0833c8912c0/e1c99/IP_Cam_Viewer_01.avif 1380w", "/en/static/43911e3d5eee235a5514a0833c8912c0/3ff05/IP_Cam_Viewer_01.avif 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/43911e3d5eee235a5514a0833c8912c0/a0b58/IP_Cam_Viewer_01.webp 230w", "/en/static/43911e3d5eee235a5514a0833c8912c0/bc10c/IP_Cam_Viewer_01.webp 460w", "/en/static/43911e3d5eee235a5514a0833c8912c0/966d8/IP_Cam_Viewer_01.webp 920w", "/en/static/43911e3d5eee235a5514a0833c8912c0/445df/IP_Cam_Viewer_01.webp 1380w", "/en/static/43911e3d5eee235a5514a0833c8912c0/0abaa/IP_Cam_Viewer_01.webp 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/43911e3d5eee235a5514a0833c8912c0/e83b4/IP_Cam_Viewer_01.jpg 230w", "/en/static/43911e3d5eee235a5514a0833c8912c0/e41a8/IP_Cam_Viewer_01.jpg 460w", "/en/static/43911e3d5eee235a5514a0833c8912c0/4d836/IP_Cam_Viewer_01.jpg 920w", "/en/static/43911e3d5eee235a5514a0833c8912c0/89b2d/IP_Cam_Viewer_01.jpg 1380w", "/en/static/43911e3d5eee235a5514a0833c8912c0/ac99c/IP_Cam_Viewer_01.jpg 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/43911e3d5eee235a5514a0833c8912c0/4d836/IP_Cam_Viewer_01.jpg",
              "alt": "IP Cam Viewer - add a RTSP Camera",
              "title": "IP Cam Viewer - add a RTSP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/ac99c/IP_Cam_Viewer_04.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAcqAXg//xAAYEAADAQEAAAAAAAAAAAAAAAAAAQISE//aAAgBAQABBQKqtG7Yup//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAEAAgMAAAAAAAAAAAAAAAABADICEZH/2gAIAQEABj8C0qMsyuT2f//EABgQAQEBAQEAAAAAAAAAAAAAAAEAYSFx/9oACAEBAAE/IVvXIUA67MHd0//aAAwDAQACAAMAAAAQc8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAEBAQADAAAAAAAAAAAAAAABEQAxcbH/2gAIAQEAAT8QJAhZQveSgkASrpF28keb/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/e4706/IP_Cam_Viewer_04.avif 230w", "/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/d1af7/IP_Cam_Viewer_04.avif 460w", "/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/7f308/IP_Cam_Viewer_04.avif 920w", "/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/e1c99/IP_Cam_Viewer_04.avif 1380w", "/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/3ff05/IP_Cam_Viewer_04.avif 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/a0b58/IP_Cam_Viewer_04.webp 230w", "/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/bc10c/IP_Cam_Viewer_04.webp 460w", "/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/966d8/IP_Cam_Viewer_04.webp 920w", "/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/445df/IP_Cam_Viewer_04.webp 1380w", "/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/0abaa/IP_Cam_Viewer_04.webp 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/e83b4/IP_Cam_Viewer_04.jpg 230w", "/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/e41a8/IP_Cam_Viewer_04.jpg 460w", "/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/4d836/IP_Cam_Viewer_04.jpg 920w", "/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/89b2d/IP_Cam_Viewer_04.jpg 1380w", "/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/ac99c/IP_Cam_Viewer_04.jpg 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4c4d7d8e035790ec5e7dd141aab74fe3/4d836/IP_Cam_Viewer_04.jpg",
              "alt": "IP Cam Viewer - add a RTSP Camera",
              "title": "IP Cam Viewer - add a RTSP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The first picture shows the integration via the `}<strong parentName="p">{`INSTAR profile`}</strong>{`. The second is the `}<strong parentName="p">{`Generic RTSP Stream over TCP`}</strong>{`. In the first case, the MJPEG stream is picked up in the second resolution, which is suitable for mobile access. In the RTSP stream, we determine the resolution by the number attached to the camera URL - `}<strong parentName="p">{`/11`}</strong>{` is the 1080p video stream (the two smaller streams are `}<strong parentName="p">{`/12`}</strong>{` and `}<strong parentName="p">{`/13`}</strong>{` available - s. `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9008_HD/Video_Streaming/"
      }}>{`RTSP Video Streaming`}</a>{`).`}</p>
    <h4 {...{
      "id": "mjpeg-stream-320p",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#mjpeg-stream-320p",
        "aria-label": "mjpeg stream 320p permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MJPEG Stream 320p`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/ac99c/IP_Cam_Viewer_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwAEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHAjKA2ubCqVd2KT//EAB4QAAIBAwUAAAAAAAAAAAAAAAECAwARMhITIiMx/9oACAEBAAEFAtKGRFWisYpU7ktYxLQDbsYs8knIZnF/f//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPwGq3Ef/xAAWEQEBAQAAAAAAAAAAAAAAAAARABD/2gAIAQIBAT8BIxv/xAAfEAABAwMFAAAAAAAAAAAAAAAAARAyESGBAgMSM5H/2gAIAQEABj8CiddcF9pfCWSnNCbaa2sSZG//xAAeEAEAAgICAwEAAAAAAAAAAAABABEhMUFhEFFxsf/aAAgBAQABPyHCmFuA6iQ3ahLAfiGGwBcGihaEY6sul5HmWdUBmXOhR34k7vU/Gf/aAAwDAQACAAMAAAAQ6x2N/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAQ/9oACAEDAQE/EHQJ/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QiMf/xAAdEAEBAAMBAAMBAAAAAAAAAAABEQAhMWFBgaHR/9oACAEBAAE/EEgVhZZE/blohKlD73CBAWH9sKmrY68wLDKb2a7h9CToeuczQ2dKMxxpEp0MxBA0E6L3BQe4olJPliY2/bP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/e4706/IP_Cam_Viewer_03.avif 230w", "/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/d1af7/IP_Cam_Viewer_03.avif 460w", "/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/7f308/IP_Cam_Viewer_03.avif 920w", "/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/e1c99/IP_Cam_Viewer_03.avif 1380w", "/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/3ff05/IP_Cam_Viewer_03.avif 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/a0b58/IP_Cam_Viewer_03.webp 230w", "/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/bc10c/IP_Cam_Viewer_03.webp 460w", "/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/966d8/IP_Cam_Viewer_03.webp 920w", "/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/445df/IP_Cam_Viewer_03.webp 1380w", "/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/0abaa/IP_Cam_Viewer_03.webp 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/e83b4/IP_Cam_Viewer_03.jpg 230w", "/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/e41a8/IP_Cam_Viewer_03.jpg 460w", "/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/4d836/IP_Cam_Viewer_03.jpg 920w", "/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/89b2d/IP_Cam_Viewer_03.jpg 1380w", "/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/ac99c/IP_Cam_Viewer_03.jpg 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9eb1c9ae64f0b7c6000be9910ae791fa/4d836/IP_Cam_Viewer_03.jpg",
              "alt": "MJPEG Stream 320p",
              "title": "MJPEG Stream 320p",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "rtsp-stream-1080p",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#rtsp-stream-1080p",
        "aria-label": "rtsp stream 1080p permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RTSP Stream 1080p`}</h4>
    <p><strong parentName="p">{`Video recordings will stay Full HD!`}</strong></p>
    <p>{`As you can see from the pictures, the smaller stream on small mobile displays is hardly distinguishable from the Full HD stream - and in view of the high bandwidth requirements of the latter, often to prefer. Keep in mind that `}<strong parentName="p">{`video recordings and alarm recordings`}</strong>{` continue to be `}<strong parentName="p">{`1080p resolution`}</strong>{`. So you can download alarm videos from the SD card of the camera and play in full resolution, even if you see in the live video only the 320p stream.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      